
import { Component, Vue } from "vue-property-decorator";
import { request } from "@/service/libs/index";

import { Toast } from "vant";

@Component({
  name: "DrawMoney",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class About extends Vue {
  private drawObj: any = {
    amount: "",
  };

  private drawInfo: any = {
    maxDrawMoney: 100, // 最大可提款数
    minMoney: 1,
    maxMoney: 100,
  };
  private channelId: string = "1001";
  //限制只能输入两位小数

  private limitInput(e: any) {
    e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
  }

  // 查询最大最小提款金额详情
  private async getCanDrawMoneyInfo() {
    let result: any = await request({
      method: "GET",
      path: "/v1/api/user/draw/channel/info?channelId=" + this.channelId,
    });
    console.log(this.drawInfo);
    if (result && result.channelId) {
      this.drawInfo = result;
    }
  }
  // private onSubmit() {
  //   console.log(1);
  // }

  private async drawMethod() {
    let obj = JSON.parse(JSON.stringify(this.drawObj));
    obj.amount = Number(obj.amount);
    let result: any = await request({
      method: "POST",
      path: "/v1/api/user/draw/apply/bankWire",
      data: obj,
    });
    if (result && result.orderNo) {
      // 跳转进入下一个页面
      localStorage.setItem("draw-order-info", JSON.stringify(result));
      setTimeout(() => {
        this.$router.push({ name: "draw-money-info" });
      }, 200);
    }
  }
  private handleBuy() {
    if (this.drawObj.amount < this.drawInfo.minMoney) {
      Toast(this.$t("zuiditiakuanjine") + "$" + this.drawInfo.minMoney);
      return;
    }
    if (this.drawObj.amount > this.drawInfo.maxMoney) {
      Toast(this.$t("zuigaotikuanjine") + "$" + this.drawInfo.maxMoney);
      return;
    }
    (this.$refs["draw"] as HTMLFormElement)
      .validate()
      .then((vaild: boolean) => {
        if (vaild) {
        } else {
          this.drawMethod();
          return false;
        }
      });
  }
  mounted() {
    this.getCanDrawMoneyInfo();
  }
}
